<template>
  <r-card>
    <div class="outstanding-request-dashboard">
      <b-table
        height="250"
        :data="data"
        :per-page="perPage"
        :loading="isLoading"
        refs="oustandingTable"
        @page-change="onOutstandingPageChange"
        hoverable
        narrowed
        backend-sorting
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        :sticky-header="true"
        class="outstanding-table"
        @sort="onSort"
      >
        <template slot="empty">
          <div
            class="dashboard-box is-flex is-align-items-center is-justify-content-center"
          >
            <div class="columns is-multiline has-text-centered">
              <div class="column is-12">
                <b-icon
                  type="is-primary"
                  icon="clock-check"
                  custom-size="mdi-48px"
                ></b-icon>
              </div>
              <div class="column is-12">
                <p class="has-text-grey-light is-size-5">
                  No Outstanding Request
                </p>
              </div>
            </div>
          </div>
        </template>
        <b-table-column label="No" v-slot="props">
          {{ props.index + 1 }}
        </b-table-column>

        <b-table-column field="id" label="ID Request" v-slot="props" sortable>
          <span class="is-capitalize">
            {{ props.row.id }}
          </span>
        </b-table-column>

        <b-table-column
          field="request_type"
          label="Request Type"
          v-slot="props"
          sortable
        >
          <span class="is-capitalize">
            {{ props.row.requestType }}
          </span>
        </b-table-column>

        <b-table-column field="created_by" label="Created By" v-slot="props">
          <span class="is-capitalize">
            {{ props.row.createdBy }}
          </span>
        </b-table-column>
      </b-table>
    </div>
  </r-card>
</template>
<script>
export default {
  props: ['data', 'page', 'lastPage', 'sortField', 'sortOrder', 'isLoading'],

  data() {
    return {
      defaultSortOrder: 'asc',
      perPage: 20,
    }
  },

  methods: {
    onOutstandingPageChange(page) {
      this.$emit('on-outstanding-page-change', page)
    },
    onSort(field, order) {
      this.$emit('on-sort-outstanding', field, order)
    },
    onScroll(divElement) {
      let that = this
      divElement.addEventListener('scroll', async function () {
        const { scrollTop, scrollHeight, clientHeight } = divElement
        if (scrollTop + clientHeight >= scrollHeight - 100) {
          if (that.page < that.lastPage) {
            that.$emit('on-scroll-outstanding')
          }
        }
      })
    },
    async loadMore() {
      let myTable = document.querySelector('.outstanding-table .table-wrapper')
      myTable.addEventListener('scroll', () => this.onScroll(myTable))
    },
  },

  mounted() {
    this.loadMore()
  },
}
</script>
