<template>
  <div v-if="title">
    <r-card class="info-container p-2">
      <div class="p-2">
        <div class="columns is-multiline is-flex is-align-items-center">
          <div class="column is-8">
            <p class="has-text-weight-bold is-size-5">{{ title }}</p>
            <p class="is-size-2 has-text-weight-bold">
              {{ totalData }}
              <span class="is-size-6">
                {{ unitMeasurement() }}
              </span>
            </p>
          </div>
          <div class="column is-4 is-12-mobile pt-0 pb-0">
            <div class="icon-container has-text-centered p-4">
              <b-icon type="is-white" :icon="icon" custom-size="mdi-36px" />
            </div>
          </div>
        </div>
      </div>
    </r-card>
  </div>
  <div v-else>
    <r-card class="has-text-centered info-container">
      <p class="is-size-2 has-text-weight-bold">Coming soon</p>
    </r-card>
  </div>
</template>
<script>
export default {
  props: ['title', 'totalData', 'unit', 'icon'],

  methods: {
    unitMeasurement: function () {
      return this.totalData > 1 ? this.unit.concat('s') : this.unit
    },
  },

  mounted() {},
}
</script>
