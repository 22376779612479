<template>
  <r-card>
    <div class="late-dashboard">
      <b-table
        height="250"
        :data="data"
        :per-page="perPage"
        :loading="isLoading"
        refs="lateTable"
        @page-change="onLatePageChange"
        hoverable
        narrowed
        backend-sorting
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        :sticky-header="true"
        class="late-table"
        @sort="onSortLate"
      >
        <template slot="empty">
          <div
            class="dashboard-box is-flex is-align-items-center is-justify-content-center"
          >
            <div class="columns is-multiline has-text-centered">
              <div class="column is-12">
                <b-icon
                  type="is-primary"
                  icon="clock-check"
                  custom-size="mdi-48px"
                ></b-icon>
              </div>
              <div class="column is-12">
                <p class="has-text-grey-light is-size-5">
                  All Employee Already Clock In On Time
                </p>
              </div>
            </div>
          </div>
        </template>
        <b-table-column label="No" v-slot="props">
          {{ props.index + 1 }}
        </b-table-column>

        <b-table-column field="full_name" label="Name" v-slot="props" sortable>
          <span class="is-capitalize">
            {{ props.row.fullName }}
          </span>
        </b-table-column>

        <b-table-column
          field="attendance_start_time"
          label="Clock In"
          v-slot="props"
          sortable
        >
          <span class="is-capitalize">
            {{ formatDate(props.row.attendanceStartTime, 'HH:mm') }}
          </span>
        </b-table-column>

        <b-table-column
          field="attendance_location_in"
          label="Location Details"
          v-slot="props"
          sortable
        >
          <span class="is-capitalize">
            {{ props.row.locationIn }}
          </span>
        </b-table-column>

        <b-table-column
          field="late_count"
          label="Late Count This Month"
          v-slot="props"
        >
          <span class="is-capitalize">
            {{ props.row.lateCount }}
            {{ props.row.lateCount > 1 ? 'times' : 'time' }}
          </span>
        </b-table-column>
      </b-table>
    </div>
  </r-card>
</template>
<script>
import { formatDate } from '../services/util'

export default {
  props: ['data', 'page', 'lastPage', 'sortField', 'sortOrder', 'isLoading'],

  data() {
    return {
      defaultSortOrder: 'asc',
      perPage: 20,
    }
  },

  methods: {
    formatDate(date, format) {
      return formatDate(date, format)
    },
    onLatePageChange(page) {
      this.$emit('on-late-page-change', page)
    },
    onSortLate(field, order) {
      this.$emit('on-sort-late', field, order)
    },
    onScroll(divElement) {
      let that = this
      divElement.addEventListener('scroll', async function () {
        const { scrollTop, scrollHeight, clientHeight } = divElement
        if (scrollTop + clientHeight >= scrollHeight - 100) {
          if (that.page < that.lastPage) {
            that.$emit('on-scroll-late')
          }
        }
      })
    },
    async loadMore() {
      let myTable = document.querySelector('.late-table .table-wrapper')
      myTable.addEventListener('scroll', () => this.onScroll(myTable))
    },
  },

  mounted() {
    this.loadMore()
  },
}
</script>
