<template>
  <div class="late-dashboard">
    <r-page>
      <template #page-header>
        <h2 class="page-title">HR Dashboard</h2>
      </template>
      <template #page-content>
        <div class="columns is-multiline">
          <div class="column is-12 is-flex is-justify-content-flex-end">
            <b-datepicker
              v-model="selectedDates"
              :mobile-native="false"
              class="dropdown-left"
              @input="selectDate"
              style="z-index: 10"
            >
              <template v-slot:trigger>
                <b-button
                  icon-left="calendar-today"
                  type="is-primary"
                  class="attendance-calendar-btn"
                >
                  {{ formatDate(selectedDates, 'll') }}
                </b-button>
              </template>
            </b-datepicker>
          </div>

          <div class="column is-3-desktop-only is-6-tablet-only is-12-mobile">
            <dashboard-card
              title="Late Clock In"
              :total-data="lateTotal"
              unit="Person"
              icon="account-clock"
            ></dashboard-card>
          </div>

          <div class="column is-3-desktop-only is-6-tablet-only is-12-mobile">
            <dashboard-card
              title="Outstanding Request"
              :total-data="requestTotal"
              unit="Request"
              icon="file"
            ></dashboard-card>
          </div>

          <div class="column is-3-desktop-only is-6-tablet-only is-12-mobile">
            <dashboard-card></dashboard-card>
          </div>
          <div class="column is-3-desktop-only is-6-tablet-only is-12-mobile">
            <dashboard-card></dashboard-card>
          </div>
        </div>

        <div class="columns is-multiline">
          <div class="column is-6-desktop is-12-tablet is-12-mobile">
            <p class="is-size-4 has-text-weight-bold">
              Late Clockin
              <span class="has-text-grey-light is-size-6">(&gt; 11Am)</span>
            </p>
            <late-clockin
              :data="lateAttendances"
              :page="latePage"
              :last-page="lateLastPage"
              :sort-field="sortField"
              :sort-order="sortOrder"
              :is-loading="isLoadingLate"
              @on-sort-late="onSortLate"
              @on-scroll-late="onScrollLate"
              @on-late-page-change="onLatePageChange"
            ></late-clockin>
          </div>
          <div class="column is-6-desktop is-12-tablet is-12-mobile">
            <p class="is-size-4 has-text-weight-bold">Outstanding Request</p>
            <outstanding-request
              :data="outstandingRequest"
              :page="requestPage"
              :last-page="lastRequestPage"
              :sort-field="sortFieldOutstanding"
              :sort-order="sortOrderOutstanding"
              :is-loading="isLoadingOutstanding"
              @on-sort-outstanding="onSortOutstanding"
              @on-scroll-outstanding="onScrollOutstanding"
              @on-outstanding-page-change="onOutstandingPageChange"
            ></outstanding-request>
          </div>
        </div>
      </template>
    </r-page>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { formatDate, showToast } from '../services/util'
import DashboardCard from '../components/DashboardCard'
import OutstandingRequest from '../components/OutstandingRequest'
import LateClockin from '../components/LateClockin'

export default {
  components: { LateClockin, OutstandingRequest, DashboardCard },
  data() {
    return {
      selectedDates: new Date(),
      latePage: 0,
      requestPage: 0,
      lateLastPage: 0,
      lastRequestPage: 0,
      perPage: 20,
      lateTotal: 0,
      requestTotal: 0,
      sortField: 'id',
      sortOrder: 'asc',
      sortFieldOutstanding: 'id',
      sortOrderOutstanding: 'asc',
      defaultSortOrder: 'asc',
      lateAttendances: [],
      outstandingRequest: [],
      isLoadingLate: false,
      isLoadingOutstanding: false,
    }
  },
  methods: {
    ...mapActions({
      actionFetchLateAttendance: 'attendance/fetchLateAttendance',
      actionFetchOutstandingRequest:
        'outstandingRequest/fetchOutstandingRequest',
    }),
    async selectDate() {
      await this.resetStateLate()
    },
    formatDate(date, format) {
      return formatDate(date, format)
    },
    onSortLate(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetStateLate()
    },
    onSortOutstanding(field, order) {
      this.sortFieldOutstanding = field
      this.sortOrderOutstanding = order
      this.resetStateOutstanding()
    },
    onLatePageChange(page) {
      this.latePage = page
      this.fetchLateAttendance()
    },
    onOutstandingPageChange(page) {
      this.requestPage = page
      this.fetchOutstandingRequest()
    },
    onScrollLate() {
      this.isLoadMore = true
      this.fetchLateAttendance()
      this.isLoadMore = false
    },
    onScrollOutstanding() {
      this.isLoadMore = true
      this.fetchOutstandingRequest()
      this.isLoadMore = false
    },
    async resetStateLate() {
      this.latePage = 0
      this.lateTotal = 0
      this.lateLastPage = 0
      this.lateAttendances = []
      await this.fetchLateAttendance()
    },
    async resetStateOutstanding() {
      this.requestPage = 0
      this.lastRequestPage = 0
      this.outstandingRequest = []
      await this.fetchOutstandingRequest()
    },
    async fetchLateAttendance() {
      this.isLoadingLate = true
      let params = {
        filtering_conditions: ['selectedDates'],
        selected_duration: [
          this.formatDate(this.selectedDates, 'YYYY-MM-DD'),
          this.formatDate(this.selectedDates, 'YYYY-MM-DD'),
        ],
        page: ++this.latePage,
        perPage: this.perPage,
        sortField: this.sortField,
        sortOrder: this.sortOrder,
      }
      try {
        let response = await this.actionFetchLateAttendance(params)
        this.lateAttendances = [...this.lateAttendances, ...response.data.data]
        this.lateTotal = response.data.meta.total
        this.lateLastPage = response.data.meta.lastPage
      } catch (err) {
        showToast(err.response.data.message)
        console.log(err)
      }
      this.isLoadingLate = false
    },
    async fetchOutstandingRequest() {
      this.isLoadingOutstanding = true
      let params = {
        page: ++this.requestPage,
        perPage: this.perPage,
        sortField: this.sortFieldOutstanding,
        sortOrder: this.sortOrderOutstanding,
      }
      try {
        let response = await this.actionFetchOutstandingRequest(params)
        this.outstandingRequest = [
          ...this.outstandingRequest,
          ...response.data.data.data,
        ]
        this.requestTotal = response.data.data.total
        this.lastRequestPage = response.data.data.lastPage
      } catch (err) {
        showToast(err.response.data.message)
        console.log(err)
      }
      this.isLoadingOutstanding = false
    },
  },
  async beforeMount() {},
  mounted() {
    this.fetchLateAttendance()
    this.fetchOutstandingRequest()
  },
}
</script>
